import { GET_ALL_ZONES, GET_ALL_RESUME_ZONES } from '../../actions/user/zones';

const initialState = {
	zones: [],
	zonesLaplata: [],
	zonesMendoza: [],
};

export const zones = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_ZONES:
			return {
				...state,
				zones: payload,
			};
		case GET_ALL_RESUME_ZONES:
			return {
				...state,
				zonesLaplata: payload?.laplata ? payload.laplata : [],
				zonesMendoza: payload?.mendoza ? payload.mendoza : [],
			};

		default:
			return state;
	}
};
