import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { clientsAdvisor } from './reducers/user/oportunidades';
import { clientDetail } from './reducers/client';
import { zones } from './reducers/user/zones';
import { allUsers } from './reducers/admin/users';
import { allClients } from './reducers/admin/clients';
import { documentation } from './reducers/admin/docs';
import { zonesAdmin } from './reducers/admin/zones';
import { orderAdvisors } from './reducers/admin/advisors';
import { developmentsConfig } from './reducers/admin/developments';
import { developmentsUser } from './reducers/user/developments';
import { assignLot } from './reducers/user/lots';
import { fichaData } from './reducers/user/ficha';
import { homeData } from './reducers/user/home';
import { datosAdmin } from './reducers/admin/datos';
// import { payment } from './reducers/admin/payments';
import { allReserves } from './reducers/admin/reservas';

const reducer = combineReducers({
	clientsAdvisor,
	zones,
	clientDetail,
	allUsers,
	allClients,
	documentation,
	zonesAdmin,
	orderAdvisors,
	developmentsConfig,
	developmentsUser,
	assignLot,
	fichaData,
	homeData,
	datosAdmin,
	// payment,
	allReserves,
});

export const store = createStore(
	reducer,
	process.env.REACT_APP_ENVIROMENT === 'develop'
		? composeWithDevTools(applyMiddleware(thunk))
		: applyMiddleware(thunk)
);
