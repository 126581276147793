import { Box, Container, Unstable_Grid2 as Grid } from '@mui/material';
import {
	OverviewSales,
	OverviewBudget,
	OverviewClients,
	OverviewTotalClients,
	OverviewTotalProfit,
	OverviewOriginClients,
} from '../components/Home';
import { formatNumber } from '../utils/formatNumberWithDots';
import { useSelector } from 'react-redux';

export const Inicio = () => {
	const state = useSelector(state => state.homeData.chartMonths);

	const loadingOrigins = useSelector(state => state.homeData.loadingOrigins);
	const data = useSelector(state => state.homeData.data);
	const target = useSelector(state => state.homeData.monthTarget);
	const value = useSelector(state => state.homeData.clientsReached);
	const newClients = useSelector(state => state.homeData.newClients);
	const monthTarget = useSelector(state => state.homeData.monthTarget);

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Grid container spacing={3}>
						<Grid xs={12} sm={6} lg={3}>
							<OverviewBudget positive sx={{ height: '100%' }} monthTarget={monthTarget} />
						</Grid>
						<Grid xs={12} sm={6} lg={3}>
							<OverviewTotalClients sx={{ height: '100%' }} newClients={newClients} />
						</Grid>
						<Grid xs={12} sm={6} lg={3}>
							<OverviewClients sx={{ height: '100%' }} value={value} />
						</Grid>
						<Grid xs={12} sm={6} lg={3}>
							<OverviewTotalProfit sx={{ height: '100%' }} target={target} />
						</Grid>
						<Grid xs={12} lg={8}>
							<OverviewSales
								chartSeries={[
									{
										name: 'Este año',
										data: state.actualResultFormatted?.map(e => formatNumber(e.raised)),
									},
									{
										name: 'Año pasado',
										data: state.prevResultFormatted?.map(e => formatNumber(e.raised)),
									},
								]}
								sx={{ height: '100%' }}
							/>
						</Grid>
						<Grid xs={12} md={6} lg={4}>
							<OverviewOriginClients
								sx={{ height: '100%' }}
								loadingOrigins={loadingOrigins}
								data={data}
							/>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
};
