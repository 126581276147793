import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box } from '@mui/material';

import { useSelector } from 'react-redux';

export const HistoryContact = () => {
	const history = useSelector(state => state.clientDetail.history);

	return (
		<Timeline position='right'>
			{history &&
				history.map(e => {
					const date = new Date(e.createdAt);
					const formattedDate = date.toLocaleString('es-ES', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
					});

					return (
						<TimelineItem key={e.id}>
							<TimelineOppositeContent color='text.secondary'>
								{formattedDate}
							</TimelineOppositeContent>

							<TimelineSeparator>
								<TimelineDot
									sx={{
										bgcolor:
											e.state === '1'
												? '#f38181'
												: e.state === '2'
												? '#f7d060'
												: e.state === '3'
												? '#3c486b'
												: e.state === '4'
												? '#5cb80b'
												: e.state === '5'
												? '#f45050'
												: e.state === '6'
												? '#00b4d8'
												: e.state === '7'
												? '#343a40'
												: 'gray',
									}}
								/>
								<TimelineConnector />
							</TimelineSeparator>

							<TimelineContent>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<span>{e.description}</span>
									<span style={{ alignSelf: 'flex-end', opacity: '0.5' }}>{e.advisor}</span>
								</Box>
							</TimelineContent>
						</TimelineItem>
					);
				})}
		</Timeline>
	);
};
