import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_DEVELOPMENTS_RESUME = 'GET_ALL_DEVELOPMENTS_RESUME';
export const GET_ALL_DEVELOPMENTS_USER = 'GET_ALL_DEVELOPMENTS_USER';
export const GET_ALL_DEVELOPMENTS_USER_ENDED = 'GET_ALL_DEVELOPMENTS_USER_ENDED';
export const GET_DEVELOPMENT = 'GET_DEVELOPMENT';
export const CHANGE_LOADER_USER_DEVELOPMENTS_ACTIVE = 'CHANGE_LOADER_USER_DEVELOPMENTS_ACTIVE';
export const CHANGE_LOADER_USER_DEVELOPMENTS_ENDED = 'CHANGE_LOADER_USER_DEVELOPMENTS_ENDED';
export const CHANGE_LOADER_DEVELOPMENT_PROFILE = 'CHANGE_LOADER_DEVELOPMENT_PROFILE';
export const GET_ALL_LOTS_DEVELOPMENT = 'GET_ALL_LOTS_DEVELOPMENT';

export const getAllDevelopmentResume = ({ page = 1, perPage = 6, querys = '' }) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_USER_DEVELOPMENTS_ACTIVE });
		const response = await authAxios(
			`${URL_API}/client/developments/active/${page}/${perPage}?${querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_DEVELOPMENTS_USER,
			payload: response,
		});
	};
};

export const getAllDevelopmentEnded = ({ pageEnded = 1, perPage = 6, querys = '' }) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_USER_DEVELOPMENTS_ENDED });
		const response = await authAxios(
			`${URL_API}/client/developments/ended/${pageEnded}/${perPage}?${querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_DEVELOPMENTS_USER_ENDED,
			payload: response,
		});
	};
};

export const getDevelopment = id => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_DEVELOPMENT_PROFILE });
		const response = await authAxios(`${URL_API}/client/development/${id}`, 'get');
		return dispatch({
			type: GET_DEVELOPMENT,
			payload: response,
		});
	};
};

export const getAllLotsDevelopment = id => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/lots/development/${id}`, 'get');
		return dispatch({
			type: GET_ALL_LOTS_DEVELOPMENT,
			payload: response,
		});
	};
};

export const updateDataLot = async data => {
	await authAxios(`${URL_API}/admin/update/lot`, 'put', null, data);
};
