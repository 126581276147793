import {
	Box,
	Card,
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableFooter,
	TablePagination,
	CircularProgress,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { Scrollbar } from '../Scrollbar';
import { ItemListDesarrollo } from './ItemListDesarrollo';
import { getAllDevelpomentsConfig } from '../../redux/actions/admin/developments';

export const DesarrollosList = ({ items = [], loading }) => {
	const dispatch = useDispatch();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (e, i) => {
		setPage(i);
	};

	const handleChangeRowsPerPage = e => {
		setRowsPerPage(e.target.value);
	};

	useEffect(() => {
		dispatch(getAllDevelpomentsConfig({ page, perPage: rowsPerPage }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage]);

	return (
		<>
			{loading ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<CircularProgress />
				</div>
			) : (
				<Card>
					<Scrollbar>
						<Box>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										<TableCell>Desarrollo</TableCell>
										<TableCell>Provincia</TableCell>
										<TableCell>Lotes</TableCell>
										<TableCell>Lotes disponibles</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								{items.developments.map(e => {
									return <ItemListDesarrollo e={e} key={e.id} />;
								})}

								<TableFooter>
									<TableRow>
										<TablePagination
											page={page}
											count={items.total}
											rowsPerPage={rowsPerPage}
											onPageChange={handleChangePage}
											rowsPerPageOptions={[10, 15, 20]}
											labelRowsPerPage='Mostrar por pagina: '
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</Box>
					</Scrollbar>
				</Card>
			)}
		</>
	);
};
