import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseIcon from '@mui/icons-material/Close';

import { CalendarVisit } from '../ContactoPerfil';
import { Scrollbar } from '../Scrollbar';

export const ModalAsignVisit = ({ open, setOpen }) => {
	const [fechaVisita, setFechaVisita] = useState(null);
	const [time, setTime] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [development, setDevelopment] = useState('');
	const [comment, setComment] = useState('');

	const handleClose = () => {
		setFechaVisita(null);
		setTime(0);
		setOpen(false);
		setIsLoading(false);
		setDevelopment('');
		setComment('');
	};

	const saveVisit = () => {
		setIsLoading(true);
		console.log(fechaVisita);
		console.log(time);
		console.log(development);
		console.log(comment);
	};

	return (
		<Scrollbar>
			<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
				<DialogTitle
					sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
				>
					Agendar visita
					<IconButton aria-label='Cerrar' onClick={handleClose}>
						<CloseIcon color='primary' />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<CalendarVisit
						development={development}
						setDevelopment={setDevelopment}
						comment={comment}
						setComment={setComment}
						setFechaVisita={setFechaVisita}
						setTime={setTime}
					/>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						loading={isLoading}
						variant='contained'
						onClick={saveVisit}
						disabled={fechaVisita === null || time === 0}
					>
						Agendar visita: {fechaVisita} - {time} hs
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<ToastContainer />
		</Scrollbar>
	);
};
