// import { GET_ALL_ADVISORS_ADMIN, CHANGE_LOADER_ADVISORS } from '../../actions/admin/advisors';
import {
	GET_ALL_RESERVES,
	GET_ALL_RESERVES_CANT,
	CHANGE_LOADER_RESERVES,
} from '../../actions/admin/reserves';

const initialState = {
	reserves: [],
	loading: false,
	reservesCant: null,
};

export const allReserves = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_RESERVES:
			return {
				...state,
				reserves: payload,
				loading: false,
			};

		case GET_ALL_RESERVES_CANT:
			return {
				...state,
				reservesCant: payload,
			};

		case CHANGE_LOADER_RESERVES:
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
};
