import { useState, useEffect } from 'react';
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { ToastContainer } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';

import { useSelector, useDispatch } from 'react-redux';
import { resetData } from '../redux/actions/admin/clients';
import { ClientsList, ClientSearch, ChangeAdvisor, ChangeStatus } from '../components/Clientes/';
import { ModalCreateContact, ModalExportLClients } from '../components/Modals';

export const Clientes = () => {
	const dispatch = useDispatch();

	const totalStates = useSelector(state => state.allClients.total);
	const itemClients = useSelector(state => state.allClients.allClients);
	const zones = useSelector(state => state.zones.zones);
	const advisors = useSelector(state => state.allClients.advisors);

	const zonesLaplata = useSelector(state => state.zones.zonesLaplata);
	const zonesMendoza = useSelector(state => state.zones.zonesMendoza);

	const { user } = useAuth();

	const [selected, setSelected] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	const handleSelect = id => {
		setSelected([...selected, id]);
	};

	const handleUnselect = id => {
		let result = selected.filter(e => e !== id);
		setSelected(result);
	};

	const [open, setOpen] = useState(false);
	const [openChangeStatus, setOpenChangeStatus] = useState(false);
	const [openChangeAdvisor, setOpenChangeAdvisor] = useState(false);
	const [openExportClients, setOpenExportClients] = useState(false);

	useEffect(() => {
		return () => {
			dispatch(resetData());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (user.roleId === 3) return;
	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<Stack spacing={1}>
								<Typography variant='h4'>Oportunidades</Typography>
								<Stack alignItems='center' direction='row' spacing={1}>
									<Button
										color='inherit'
										startIcon={
											<SvgIcon fontSize='small'>
												<ArrowDownOnSquareIcon />
											</SvgIcon>
										}
										onClick={() => setOpenExportClients(true)}
									>
										Exportar
									</Button>
									{selected.length > 0 ? (
										<>
											<Button
												color='inherit'
												startIcon={
													<SvgIcon fontSize='small'>
														<CachedIcon />
													</SvgIcon>
												}
												onClick={() => setOpenChangeAdvisor(true)}
											>
												Cambiar asesor
											</Button>
											<Button
												color='inherit'
												startIcon={
													<SvgIcon fontSize='small'>
														<AnnouncementIcon />
													</SvgIcon>
												}
												onClick={() => setOpenChangeStatus(true)}
											>
												Cambiar estado
											</Button>
										</>
									) : (
										<></>
									)}
								</Stack>
							</Stack>
							<div>
								<Button
									startIcon={
										<SvgIcon fontSize='small'>
											<PlusIcon />
										</SvgIcon>
									}
									variant='contained'
									onClick={() => setOpen(true)}
								>
									Añadir
								</Button>
							</div>
						</Stack>
						<ClientSearch
							totalStates={totalStates}
							advisors={advisors}
							zones={zones}
							setSelectAll={setSelectAll}
							setSelected={setSelected}
						/>
						<ClientsList
							itemClients={itemClients}
							handleUnselect={handleUnselect}
							handleSelect={handleSelect}
							selected={selected}
							setSelected={setSelected}
							selectAll={selectAll}
							setSelectAll={setSelectAll}
						/>
						<ModalCreateContact
							open={open}
							setOpen={setOpen}
							zonesLaplata={zonesLaplata}
							zonesMendoza={zonesMendoza}
						/>
						{user.roleId !== 1 ? (
							<></>
						) : (
							<>
								<ChangeStatus
									openChangeStatus={openChangeStatus}
									setOpenChangeStatus={setOpenChangeStatus}
									selected={selected}
									setSelected={setSelected}
									setSelectAll={setSelectAll}
								/>
								<ChangeAdvisor
									advisors={advisors}
									openChangeAdvisor={openChangeAdvisor}
									setOpenChangeAdvisor={setOpenChangeAdvisor}
									selected={selected}
									setSelected={setSelected}
									setSelectAll={setSelectAll}
								/>
							</>
						)}
					</Stack>
				</Container>
				<ToastContainer />
			</Box>
			<ModalExportLClients open={openExportClients} setOpen={setOpenExportClients} />
		</>
	);
};
