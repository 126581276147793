import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { changeState } from '../../redux/actions/user/oportunidades';
import { useDispatch, useSelector } from 'react-redux';

const StyledTabs = styled(props => (
	<Tabs
		sx={{
			marginLeft: '20px',
			'@media (max-width: 780px)': {
				marginLeft: '0px',
			},
		}}
		{...props}
		TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		backgroundColor: 'transparent',
		width: '100%',
	},
	'& .MuiTabs-flexContainer': {
		paddingLeft: '20px',
	},
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	fontWeight: theme.typography.fontWeightRegular,
	color: 'rgba(0, 0, 0, 0.38)',
	maxWidth: '250px',
	width: 'auto',
	whiteSpace: 'nowrap',
	padding: '0 30px 0 30px',
	// width: '100%',
	margin: '0 !important',
	borderTopLeftRadius: '10px',
	borderTopRightRadius: '10px',
	backgroundColor: 'rgba(244, 244, 244, 1) !important',
	'&.Mui-selected': {
		color: 'white',
		transform: 'scale(1.1, 1.1)',
	},
	'@media (max-width: 1500px)': {
		width: '20%',
		fontSize: '12px',
	},
}));

export const ListSearchbar = ({ totalStates }) => {
	const dispatch = useDispatch();
	const value = useSelector(state => state.clientsAdvisor.status);

	const handleChange = (event, newValue) => {
		dispatch(changeState(newValue));
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ marginBottom: '-5px !important', marginLeft: 2.5 }}>
				<StyledTabs value={value} onChange={handleChange}>
					<StyledTab
						sx={{ '&.Mui-selected': { backgroundColor: '#F38181 !important' } }}
						value={1}
						label={`Sin contactar (${totalStates.sinContactar ? totalStates.sinContactar : 0})`}
					/>

					<StyledTab
						sx={{ '&.Mui-selected': { backgroundColor: '#F7D060 !important' } }}
						value={2}
						label={`Asesoramiento telefónico (${
							totalStates.telefonico ? totalStates.telefonico : 0
						})`}
					/>

					<StyledTab
						sx={{ '&.Mui-selected': { backgroundColor: '#3C486B !important' } }}
						value={3}
						label={`Asesoramiento presencial (${
							totalStates.presencial ? totalStates.presencial : 0
						})`}
					/>

					<StyledTab
						sx={{ '&.Mui-selected': { backgroundColor: '#00b4d8 !important' } }}
						value={6}
						label={`Reservado (${totalStates.reservado ? totalStates.reservado : 0})`}
					/>

					<StyledTab
						sx={{ '&.Mui-selected': { backgroundColor: '#5CB80B !important' } }}
						value={4}
						label={`Alta (${totalStates.alta ? totalStates.alta : 0})`}
					/>

					<StyledTab
						sx={{ '&.Mui-selected': { backgroundColor: '#F45050 !important' } }}
						value={5}
						label={`Baja (${totalStates.baja ? totalStates.baja : 0})`}
					/>

					<StyledTab
						sx={{ '&.Mui-selected': { backgroundColor: '#343a40 !important' } }}
						value={7}
						label={`Reservas caidas (${
							totalStates.reservasCaidas ? totalStates.reservasCaidas : 0
						})`}
					/>
				</StyledTabs>
			</Box>
		</Box>
	);
};
