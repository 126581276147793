import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { ListClients, ListSearch } from '../components/Oportunidades';
import { ModalCreateContact } from '../components/Modals';
import { useAuth } from '../hooks/useAuth';

export const Oportunidades = () => {
	const { user } = useAuth();

	const clients = useSelector(state => state.clientsAdvisor.allClients);
	const totalStates = useSelector(state => state.clientsAdvisor.total);
	const status = useSelector(state => state.clientsAdvisor.status);
	const order = useSelector(state => state.clientsAdvisor.order);
	const zones = useSelector(state => state.zones.zones);

	const zonesLaplata = useSelector(state => state.zones.zonesLaplata);
	const zonesMendoza = useSelector(state => state.zones.zonesMendoza);

	const [open, setOpen] = useState(false);

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<Stack spacing={1}>
								<Typography variant='h4'>Oportunidades</Typography>
								<Stack alignItems='center' direction='row' spacing={1}>
									<Button
										color='inherit'
										disabled
										startIcon={
											<SvgIcon fontSize='small'>
												<ArrowUpOnSquareIcon />
											</SvgIcon>
										}
									>
										Importar
									</Button>
									<Button
										color='inherit'
										disabled
										startIcon={
											<SvgIcon fontSize='small'>
												<ArrowDownOnSquareIcon />
											</SvgIcon>
										}
									>
										Exportar
									</Button>
								</Stack>
							</Stack>
							<span>
								<Button
									startIcon={
										<SvgIcon fontSize='small'>
											<PlusIcon />
										</SvgIcon>
									}
									variant='contained'
									onClick={() => setOpen(true)}
								>
									Añadir
								</Button>
							</span>
						</Stack>
						{user.areaRol !== 1 ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '40px',
									fontSize: '18px',
								}}
							>
								No eres asesor
							</div>
						) : (
							<>
								<ListSearch
									zones={zones}
									order={order}
									statusE={status}
									totalStates={totalStates}
								/>

								<ListClients order={order} items={clients} />
							</>
						)}
					</Stack>
				</Container>
			</Box>
			<ModalCreateContact
				open={open}
				setOpen={setOpen}
				zonesLaplata={zonesLaplata}
				zonesMendoza={zonesMendoza}
			/>
		</>
	);
};
