import {
	GET_ALL_DEVELOPMENTS_USER,
	GET_ALL_DEVELOPMENTS_USER_ENDED,
	CHANGE_LOADER_USER_DEVELOPMENTS_ACTIVE,
	CHANGE_LOADER_USER_DEVELOPMENTS_ENDED,
	CHANGE_LOADER_DEVELOPMENT_PROFILE,
	GET_DEVELOPMENT,
	GET_ALL_LOTS_DEVELOPMENT,
} from '../../actions/user/developments';

const initialState = {
	developmentsActive: [],
	developmentsEnded: [],
	loadingActive: false,
	loadingEnded: false,
	loadingPerfil: false,
	developmentPerfil: [],
	lots: [],
};

export const developmentsUser = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_DEVELOPMENTS_USER:
			return {
				...state,
				developmentsActive: payload,
				loadingActive: false,
			};
		case GET_ALL_DEVELOPMENTS_USER_ENDED:
			return {
				...state,
				developmentsEnded: payload,
				loadingEnded: false,
			};
		case CHANGE_LOADER_USER_DEVELOPMENTS_ACTIVE:
			return {
				...state,
				loadingActive: true,
			};
		case CHANGE_LOADER_USER_DEVELOPMENTS_ENDED:
			return {
				...state,
				loadingEnded: true,
			};
		case CHANGE_LOADER_DEVELOPMENT_PROFILE:
			return {
				...state,
				loadingPerfil: true,
			};
		case GET_DEVELOPMENT:
			return {
				...state,
				developmentPerfil: payload,
				loadingPerfil: false,
			};
		case GET_ALL_LOTS_DEVELOPMENT:
			let res = payload.sort((a, b) => a.numberLot - b.numberLot);
			return {
				...state,
				lots: res,
			};

		default:
			return state;
	}
};
