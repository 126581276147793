import React, { useRef, useState, useMemo, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';

export const Map = ({ coordinates, name }) => {
	const [position, setPosition] = useState(coordinates);
	const [center, setCenter] = useState(coordinates);

	const markerRef = useRef(null);
	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current;
				if (marker != null) {
					coordinates(marker.getLatLng());
					setPosition(marker.getLatLng());
				}
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		setPosition(coordinates);
		setCenter(coordinates);
	}, [coordinates]);

	if (coordinates.length < 1) return null;

	return (
		<MapContainer
			zoom={14}
			center={center}
			scrollWheelZoom={false}
			style={{ height: '100%', width: '100%', borderRadius: '7px' }}
		>
			<TileLayer
				url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibGF1dGFyb2QiLCJhIjoiY2xndXdpdnMyMGsyMTNmbnVjdXdmNmJ5ayJ9.qGFpVJUPZM3EbLEeJitP4A`}
			/>
			<Marker eventHandlers={eventHandlers} position={position} ref={markerRef} animate={true}>
				<Popup>{name}</Popup>
			</Marker>
		</MapContainer>
	);
};
