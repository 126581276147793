import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthProvider } from './contexts/authContext';
import { createTheme } from './theme';

import 'simplebar-react/dist/simplebar.min.css';

import { createEmotionCache } from './utils/createEmotionCache';

import { caching } from './utils/cacheCleaner';

import {
	Inicio,
	Login,
	NoMatch,
	Oportunidades,
	ContactoPerfil,
	Usuarios,
	UsuarioPerfil,
	Clientes,
	Configuraciones,
	Documentacion,
	Zonas,
	// Asesores,
	ConfigDesarrollos,
	Desarrollos,
	DesarrolloPerfil,
	Ficha,
	Datos,
	PerfilPersonal,
	Calendario,
	ErrorPage,
	// EditarDesarrollo,
	//ConfigPagos,
	Reservas,
	// Sucursales,
} from './pages';
import { LayoutAuth, LayoutDashboard } from './layouts';
import { AppLoader } from './components/Loader';

import { getAllAdvisorClients, getTotalClientsAdvisor } from './redux/actions/user/oportunidades';
import { getDataClient, getLotsClient, getHistoryClient } from './redux/actions/client';
import {
	getAllUsers,
	getDataUser,
	// originDataUser
} from './redux/actions/admin/users';
import { getAllClients, getTotalClientsAdmin, getAdvisors } from './redux/actions/admin/clients';
import { getZones, getResumeZones } from './redux/actions/user/zones';
import { getAllDocuments } from './redux/actions/admin/docs';
//import { getAllPayments } from './redux/actions/admin/payments';
import { getAllZones } from './redux/actions/admin/zones';
// import { getAllAdvisors } from './redux/actions/admin/advisors';
import { getAllDevelpomentsConfig } from './redux/actions/admin/developments';
import {
	getAllDevelopmentResume,
	getDevelopment,
	getAllDevelopmentEnded,
} from './redux/actions/user/developments';
import { getAllReserves, getAllReservesCant } from './redux/actions/admin/reserves';
import { getAllDataLot } from './redux/actions/user/ficha';

import {
	getOriginData,
	getChartOriginData,
	getClientsReached,
	getMonthTarget,
	getNewClients,
	getChartMonth,
	// getReservasVencimiento,
} from './redux/actions/user/home';
import { getHistoryTarget } from './redux/actions/admin/datos';

function Index() {
	const dispatch = useDispatch();

	const router = createBrowserRouter([
		{
			element: <LayoutDashboard />,
			loader: async () => {
				// dispatch(getReservasVencimiento());
				return null;
			},
			children: [
				{
					path: '/',
					element: <Inicio />,
					loader: async () => {
						dispatch(getOriginData());
						dispatch(getNewClients());
						dispatch(getChartMonth());
						dispatch(getMonthTarget());
						dispatch(getClientsReached());
						dispatch(getChartOriginData());
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/oportunidades',
					element: <Oportunidades />,
					loader: async () => {
						dispatch(getAllAdvisorClients());
						dispatch(getTotalClientsAdvisor());
						dispatch(getResumeZones());
						dispatch(getZones());
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/calendario',
					element: <Calendario />,
					loader: async () => {
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/desarrollos',
					element: <Desarrollos />,
					loader: async () => {
						dispatch(getAllDevelopmentResume({ page: 1, perPage: 6 }));
						dispatch(getAllDevelopmentEnded({ page: 1, perPage: 6 }));
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/desarrollo/:id',
					element: <DesarrolloPerfil />,
					loader: async ({ params }) => {
						dispatch(getDevelopment(params.id));
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/contacto/:id',
					element: <ContactoPerfil />,
					loader: async ({ params }) => {
						dispatch(getDataClient(params));
						dispatch(getLotsClient(params));
						dispatch(getHistoryClient(params.id));
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/usuarios',
					element: <Usuarios />,
					loader: async () => {
						dispatch(getAllUsers());
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/usuario/:id',
					element: <UsuarioPerfil />,
					loader: async ({ params }) => {
						dispatch(getDataUser(params.id));
						// dispatch(originDataUser(params.id));
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/perfil',
					element: <PerfilPersonal />,
					loader: async () => {
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/clientes',
					element: <Clientes />,
					loader: async () => {
						dispatch(getTotalClientsAdmin());
						dispatch(getResumeZones());
						dispatch(getAllClients());
						dispatch(getAdvisors());
						dispatch(getZones());

						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/configuraciones',
					element: <Configuraciones />,
					loader: async () => {
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/documentacion',
					element: <Documentacion />,
					loader: async () => {
						dispatch(getAllDocuments());
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/zonas',
					element: <Zonas />,
					loader: async () => {
						dispatch(getAllZones());
						return null;
					},
					errorElement: <ErrorPage />,
				},
				{
					path: '/datos',
					element: <Datos />,
					loader: async () => {
						let year = new Date().getFullYear();
						dispatch(getHistoryTarget(year));
						return null;
					},
					errorElement: <ErrorPage />,
				},
				// {
				// 	path: '/asesores',
				// 	element: <Asesores />,
				// 	loader: async () => {
				// 		dispatch(getAllAdvisors());
				// 		return null;
				// 	},
				// },
				{
					path: '/config/desarrollos',
					element: <ConfigDesarrollos />,
					loader: async () => {
						dispatch(getAllDevelpomentsConfig({ page: 0, perPage: 10 }));
						dispatch(getAllDocuments());
						return null;
					},
					errorElement: <ErrorPage />,
				},
				// {
				// 	path: '/config/medios-de-pago',
				// 	element: <ConfigPagos />,
				// 	loader: async () => {
				// 		dispatch(getAllPayments());
				// 		return null;
				// 	},
				// 	errorElement: <ErrorPage />,
				// },
				// {
				// 	path: '/editar/desarrollo/:id',
				// 	element: <EditarDesarrollo />,
				// 	loader: async ({ params }) => {
				// 		dispatch(getDevelopment(params.id));
				// 		return null;
				// 	},
				// 	errorElement: <ErrorPage />,
				// },
				{
					path: '/reservas',
					element: <Reservas />,
					loader: async () => {
						dispatch(getAllReserves({ active: 'reservasVencidas', limit: 10, offset: 0 }));
						dispatch(getAllReservesCant());
						return null;
					},
					errorElement: <ErrorPage />,
				},
				// {
				// 	path: '/sucursales',
				// 	element: <Sucursales />,
				// 	loader: async () => {
				// 		return null;
				// 	},
				// },
				{
					path: '/ficha/:id/:desarrollo/:lote',
					element: <Ficha />,
					loader: async ({ params }) => {
						dispatch(getAllDataLot(params.id, params.desarrollo));
						return null;
					},
					errorElement: <ErrorPage />,
				},
			],
		},
		{
			path: '/login',
			element: (
				<LayoutAuth>
					<Login />
				</LayoutAuth>
			),
		},
		{
			path: '*',
			element: <NoMatch />,
		},
	]);
	return <RouterProvider router={router} fallbackElement={<AppLoader />} />;
}
const App = () => {
	const theme = createTheme();

	useEffect(() => {
		caching();
	}, []);

	return (
		<>
			<CacheProvider value={createEmotionCache()}>
				<AuthProvider>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<Index />
					</ThemeProvider>
				</AuthProvider>
			</CacheProvider>
		</>
	);
};

export default App;
