import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { formatDate } from '../../utils/formatDate';
import { CircularProgress } from '@mui/material';

const stateDescriptions = {
	1: 'Sin contactar',
	2: 'Asesoramiento telefónico',
	3: 'Asesoramiento presencial',
	4: 'Alta',
	5: 'Baja',
	6: 'Reservado',
	7: 'Reserva caída',
};

export const DuplicateDataDialog = ({ open, setOpen, info, setDuplicateData }) => {
	const handleClose = e => {
		setDuplicateData(e);
		setOpen(false);
	};

	let bgColor = 'error.main';

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				{info === undefined || info === null ? (
					<div style={{ padding: '10px' }}>
						<CircularProgress />
					</div>
				) : (
					<>
						<DialogTitle
							id='alert-dialog-title'
							sx={{
								backgroundColor: bgColor,
								color: 'white',
								mb: '15px',
								display: 'flex',
								gap: '15px',
							}}
						>
							<WarningIcon />
							Dato ya en el sistema
						</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>
								Este dato ya se encuentra en el sistema.
								<br />
								<br />
								<Typography variant='caption'>
									Asesor: {info?.User ? info.User.fullName : 'Sin asesor'}
								</Typography>
								<br />
								<Typography variant='caption'>
									Estado: {stateDescriptions[info.StateId] || 'Estado desconocido'}
								</Typography>
								<br />
								<Typography variant='caption'>
									Registrado el: {formatDate(info.createdAt)}
								</Typography>
								<br />
								<Typography variant='caption'>
									Última actualización: {formatDate(info.updatedAt)}
								</Typography>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => handleClose(false)} autoFocus variant='contained'>
								Cerrar
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
		</div>
	);
};
