import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import { useAuth } from '../hooks/useAuth';

import { TargetRaisedMonth } from '../components/Datos';

export const Datos = () => {
	const { user } = useAuth();

	if (user.roleId === 3) return;
	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<TargetRaisedMonth />
			</Box>
			<ToastContainer />
		</>
	);
};
