import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import TransferList from './TransferList';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	})
);

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		justifyContent: 'space-between',
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const AccordionDevelopment = ({
	name,
	userId,
	expanded,
	dataClient,
	handleClose,
	availableLot,
	handleChange,
	developmentId,
}) => {
	const [open, setOpen] = useState(false);

	const handleAccordionChange = panel => (event, isExpanded) => {
		// Maneja el cambio de acordeón
		handleChange(panel)(event, isExpanded);
		setOpen(isExpanded); // Actualiza el estado abierto/cerrado
	};

	return (
		<Accordion expanded={expanded === name} onChange={handleAccordionChange(name)}>
			<AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
				<Typography>{name}</Typography>
				<Typography variant='caption'>Disponibles: {availableLot}</Typography>
			</AccordionSummary>

			<AccordionDetails>
				{open && (
					<TransferList
						dataClient={dataClient}
						handleClose={handleClose}
						availableLot={availableLot}
						developmentId={developmentId}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	);
};
