import React, { useState } from 'react';
import {
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Typography,
	Radio,
	Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { Scrollbar } from '../Scrollbar/Scrollbar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { WarningDialog, DuplicateDataDialog } from '../Dialogs';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { createClient, getClientDuplicated } from '../../redux/actions/admin/clients';
import { addClient } from '../../redux/actions/user/oportunidades';
import { provinces, origins } from '../../lib';

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		margin: '5px',
	},
});

const regex = /^[0-9]+$/;

export const ModalCreateContact = ({ open, setOpen, zonesLaplata = [], zonesMendoza = [] }) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [isDirty, setIsDirty] = useState(false);
	const [warning, setWarning] = useState(false);
	const [info, setInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [duplicateData, setDuplicateData] = useState(false);

	const { user } = useAuth();

	const handleClose = () => {
		if (isDirty) return setWarning(true);
		else {
			setOpen(false);
			formik.resetForm();
		}
	};

	const handleDirty = () => {
		setWarning(false);
		setIsDirty(false);
		setOpen(false);
		formik.resetForm();
	};

	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async (formValue, { resetForm }) => {
			try {
				formValue.createdBy = user.fullName;
				if (formik.isSubmitting) setIsLoading(true);
				let response;
				if (location.pathname === '/oportunidades') {
					response = await dispatch(addClient(formValue));
				} else {
					response = await dispatch(createClient(formValue));
				}

				if (response === 409) {
					const clientDuplicated = await getClientDuplicated(formValue.cellphone);
					setInfo(clientDuplicated);
					setDuplicateData(true);
					setIsLoading(false);
					return;
				}

				toast.success('Usuario creado exitosamente.', { theme: 'colored' });
				setIsLoading(false);
				setIsDirty(false);
				setOpen(false);
				resetForm();
			} catch (error) {
				console.error(error);
				setIsLoading(false);
				return toast.error('¡Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
			}
		},
	});

	return (
		<>
			<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
				<DialogTitle>Añadir nuevo contacto</DialogTitle>
				<Scrollbar>
					<DialogContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							width: '100% !important',
							pt: '15px !important',
						}}
					>
						<form onSubmit={formik.handleSubmit}>
							<Typography variant='subtitle2' sx={{ marginLeft: '10px' }}>
								Datos personales
							</Typography>
							<div>
								<TextFieldContact
									required
									id='name'
									label='Nombre'
									variant='filled'
									name='name'
									sx={{ width: '48%' }}
									value={formik.values.name.charAt(0).toUpperCase() + formik.values.name.slice(1)}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
									error={!!formik.errors.name}
								/>
								<TextFieldContact
									id='lastName'
									label='Apellido'
									variant='filled'
									name='lastName'
									sx={{ width: '48%' }}
									value={
										formik.values.lastName.charAt(0).toUpperCase() + formik.values.lastName.slice(1)
									}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
									error={!!formik.errors.lastName}
								/>
							</div>
							<div>
								<TextFieldContact
									sx={{ width: '20%' }}
									label='Tipo documento'
									name='TypeDocId'
									select
									SelectProps={{ native: true }}
									value={formik.values.TypeDocId}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
								>
									<option key={1} value='dni'>
										DNI
									</option>
									<option key={2} value='pasaporte'>
										Pasaporte
									</option>
									<option key={3} value='le'>
										LE
									</option>
								</TextFieldContact>
								<TextFieldContact
									id='nroDoc'
									label='Número documento'
									variant='filled'
									name='numberId'
									value={formik.values.numberId}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
									error={!!formik.errors.numberId}
								/>
							</div>

							<Typography variant='subtitle2' sx={{ marginLeft: '10px', mt: '30px' }}>
								Datos de contacto
							</Typography>
							<div style={{ display: 'flex' }}>
								<TextFieldContact
									id='telefonoMovil'
									label='Telefono movil'
									variant='filled'
									name='cellphone'
									required
									// type='number'
									sx={{ width: '50%' }}
									value={formik.values.cellphone}
									onChange={e => {
										if (e.target.value.length === 11) return;
										if (!regex.test(e.target.value)) return;
										formik.handleChange(e);
										setIsDirty(true);
									}}
									error={!!formik.errors.cellphone}
								/>
								<TextFieldContact
									id='mail'
									label='Email'
									variant='filled'
									name='email'
									sx={{ width: '50%' }}
									value={formik.values.email}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
									error={!!formik.errors.email}
								/>
							</div>
							<Typography variant='subtitle2' sx={{ marginLeft: '10px', mt: '30px' }}>
								Direcciones
							</Typography>
							<div style={{ display: 'flex' }}>
								<TextFieldContact
									id='direccion'
									label='Dirección'
									variant='filled'
									name='address'
									value={formik.values.address}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
									error={!!formik.errors.address}
									sx={{ width: '40%' }}
								/>
								<TextFieldContact
									id='localidad'
									label='Localidad'
									variant='filled'
									name='locality'
									sx={{ width: '40%' }}
									value={formik.values.locality}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
									error={!!formik.errors.locality}
								/>
								<TextFieldContact
									sx={{ width: '20%' }}
									label='Provincia'
									name='province'
									select
									SelectProps={{ native: true }}
									value={formik.values.province}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
								>
									{provinces.map((option, index) => (
										<option key={index} value={option.value}>
											{option.label}
										</option>
									))}
								</TextFieldContact>
							</div>
							<Typography variant='subtitle2' sx={{ marginLeft: '10px', mt: '30px' }}>
								Datos de interés
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row' }}>
								<TextFieldContact
									label='Origen del dato'
									name='origin'
									select
									SelectProps={{ native: true }}
									value={formik.values.origin}
									error={!!formik.errors.origin}
									onChange={e => {
										formik.handleChange(e);
										setIsDirty(true);
									}}
								>
									{origins.map((e, i) => (
										<option key={i * Math.random()} value={e.value}>
											{e.label}
										</option>
									))}
								</TextFieldContact>

								<Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Radio
											checked={formik.values.selectedZone === 'laPlata'}
											value='laPlata'
											size='small'
											name='selectedZone'
											onChange={formik.handleChange}
											inputProps={{ 'aria-label': 'La Plata' }}
										/>
										<Typography fontSize={14}>La Plata</Typography>
									</Box>

									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											height: '16px',
										}}
									>
										<Radio
											checked={formik.values.selectedZone === 'mendoza'}
											size='small'
											value='mendoza'
											name='selectedZone'
											onChange={formik.handleChange}
											inputProps={{ 'aria-label': 'Mendoza' }}
										/>
										<Typography fontSize={14}>Mendoza</Typography>
									</Box>
								</Box>

								{formik.values.selectedZone === 'laPlata' ? (
									<TextFieldContact
										label='Zonas La Plata'
										name='zone'
										select
										SelectProps={{ native: true }}
										value={formik.values.zone}
										error={!!formik.errors.zone}
										onChange={e => {
											formik.handleChange(e);
											setIsDirty(true);
										}}
									>
										<option key={9999} value={0}>
											Sin especificar
										</option>
										{zonesLaplata.map((e, i) => (
											<option key={i * Math.random()} value={e.id}>
												{e.description}
											</option>
										))}
									</TextFieldContact>
								) : (
									<TextFieldContact
										label='Zonas Mendoza'
										name='zone'
										select
										SelectProps={{ native: true }}
										value={formik.values.zone}
										error={!!formik.errors.zone}
										onChange={e => {
											formik.handleChange(e);
											setIsDirty(true);
										}}
									>
										<option key={9999} value={9999}>
											Sin especificar
										</option>
										{zonesMendoza.map((e, i) => (
											<option key={i * Math.random()} value={e.id}>
												{e.description}
											</option>
										))}
									</TextFieldContact>
								)}
							</Box>

							<TextFieldContact
								fullWidth
								label='Comentarios'
								name='lastNote'
								multiline
								rows={3}
								value={formik.values.lastNote}
								error={!!formik.errors.lastNote}
								onChange={e => {
									formik.handleChange(e);
									setIsDirty(true);
								}}
							></TextFieldContact>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancelar</Button>
						<LoadingButton
							variant='contained'
							type='submit'
							loading={isLoading}
							onClick={formik.handleSubmit}
						>
							Añadir
						</LoadingButton>
					</DialogActions>
				</Scrollbar>
			</Dialog>
			<WarningDialog
				open={warning}
				setOpen={setWarning}
				goBack={handleDirty}
				resetForm={() => formik.resetForm()}
			/>
			<DuplicateDataDialog
				setOpen={setOpen}
				info={info}
				open={duplicateData}
				setDuplicateData={setDuplicateData}
				goBack={handleDirty}
			/>
			<ToastContainer />
		</>
	);
};

function validationSchema() {
	return Yup.object({
		name: Yup.string().required(true),
		lastName: Yup.string(),
		email: Yup.string().email(true),
		address: Yup.string(),
		cellphone: Yup.string().required(true),
		locality: Yup.string(),
		province: Yup.string(),
		numberId: Yup.string(),
		TypeDocId: Yup.number(),
		lastNote: Yup.string().required(true),
		selectedZone: Yup.string(),
		zone: Yup.number().required(true),
		origin: Yup.string().required(true),
	});
}

function initialValues() {
	return {
		name: '',
		lastName: '',
		email: '',
		address: '',
		cellphone: '',
		locality: '',
		province: 'bsas',
		numberId: '',
		TypeDocId: 1,
		StateId: 1,
		createdBy: '',
		lastNote: undefined,
		selectedZone: 'laPlata',
		zone: undefined,
		origin: undefined,
	};
}
