import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import HomeIcon from '@mui/icons-material/Home';
import StarIcon from '@mui/icons-material/Star';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HandymanIcon from '@mui/icons-material/Handyman';

import { SvgIcon } from '@mui/material';

export const items = [
	{
		title: 'Inicio',
		path: '/',
		icon: (
			<SvgIcon fontSize='small'>
				<HomeIcon />
			</SvgIcon>
		),
	},
	{
		title: 'Oportunidades',
		path: '/oportunidades',
		icon: (
			<SvgIcon fontSize='small'>
				<StarIcon />
			</SvgIcon>
		),
	},
	// {
	// 	title: 'Calendario',
	// 	path: '/calendario',
	// 	icon: (
	// 		<SvgIcon fontSize='small'>
	// 			<CalendarMonthIcon />
	// 		</SvgIcon>
	// 	),
	// },
	{
		title: 'Desarrollos',
		path: '/desarrollos',
		icon: (
			<SvgIcon fontSize='small'>
				<HandymanIcon />
			</SvgIcon>
		),
	},
];

export const itemsAdmin = [
	{
		title: 'Usuarios',
		path: '/usuarios',
		icon: (
			<SvgIcon fontSize='small'>
				<ManageAccountsIcon />
			</SvgIcon>
		),
	},
	{
		title: 'Clientes',
		path: '/clientes',
		icon: (
			<SvgIcon fontSize='small'>
				<UsersIcon />
			</SvgIcon>
		),
	},
	{
		title: 'Reservas',
		path: '/reservas',
		icon: (
			<SvgIcon fontSize='small'>
				<DescriptionIcon />
			</SvgIcon>
		),
	},
	// {
	//     title: "Rendimientos",
	//     path: "/rendimientos",
	//     icon: (
	//         <SvgIcon fontSize='small'>
	//             <QueryStatsIcon />
	//         </SvgIcon>
	//     ),
	// },
	{
		title: 'Configuraciones',
		path: '/configuraciones',
		icon: (
			<SvgIcon fontSize='small'>
				<SettingsIcon />
			</SvgIcon>
		),
	},
];

export const itemsMod = [
	{
		title: 'Clientes',
		path: '/clientes',
		icon: (
			<SvgIcon fontSize='small'>
				<UsersIcon />
			</SvgIcon>
		),
	},
];
