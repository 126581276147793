import { useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, Link } from 'react-router-dom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { styled, Divider, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { formatDate } from '../../utils/formatDate';
import { deleteReserva, getHistoryClient } from '../../redux/actions/client';

const TooltipStyled = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}));

export const ContactLotDetail = ({ e }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [deleting, setDeleting] = useState(false);

	const handleDeleteReserva = async e => {
		setDeleting(true);
		await dispatch(deleteReserva(e));
		await dispatch(getHistoryClient(e.ContactId));
		setDeleting(false);
	};

	return (
		<>
			<span style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Link
					to={`/desarrollo/${e.DevelopmentId}`}
					style={{ textDecoration: 'none', color: 'inherit' }}
				>
					<Typography gutterBottom variant='h6'>
						{e.Development?.name ? e.Development.name : 'ERROR'}
					</Typography>
				</Link>
				<Typography gutterBottom variant='body2'>
					Lote: {e.numberLot ? e.numberLot : 'ERROR'}
				</Typography>
			</span>
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'baseline',
				}}
			>
				<Typography gutterBottom variant='caption'>
					{e.updatedAt ? formatDate(e.updatedAt) : 'ERROR'}
				</Typography>
				<span
					style={{
						display: 'flex',
						alignItems: 'baseline',
					}}
				>
					<TooltipStyled title='Crear ficha'>
						<LoadingButton
							size='small'
							sx={{ p: 0, minWidth: '40px', width: '24px', height: '40px' }}
							onClick={() => navigate(`/ficha/${e.ContactId}/${e.DevelopmentId}/${e.numberLot}`)}
						>
							<NoteAddIcon />
						</LoadingButton>
					</TooltipStyled>
					<TooltipStyled title='Eliminar reserva'>
						<LoadingButton
							size='small'
							loading={deleting}
							onClick={() => handleDeleteReserva(e)}
							sx={{ p: 0, minWidth: '40px', width: '24px', height: '40px' }}
						>
							<DeleteForeverIcon />
						</LoadingButton>
					</TooltipStyled>
				</span>
			</span>
			<Divider sx={{ width: '100%' }} />
		</>
	);
};
