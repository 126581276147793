import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_RESERVES = 'GET_ALL_RESERVES';
export const CHANGE_LOADER_RESERVES = 'CHANGE_LOADER_RESERVES';
export const CHANGE_STATE = 'CHANGE_STATE';
export const GET_ALL_RESERVES_CANT = 'GET_ALL_RESERVES_CANT';
export const UPDATE_RESERVE_STATE = 'UPDATE_RESERVE_STATE';

export const getAllReserves = ({ active, limit, offset }) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_RESERVES });
		const response = await authAxios(
			`${URL_API}/admin/all/reservas/${active}/${limit}/${offset}`,
			'get'
		);

		return dispatch({
			type: GET_ALL_RESERVES,
			payload: response,
		});
	};
};

export const updateReserveState = (id, active, params) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_RESERVES });
		await authAxios(`${URL_API}/admin/reserva/${id}`, 'put', null, { ...active });
		dispatch(getAllReservesCant());
		const response = await authAxios(`${URL_API}/admin/all/reservas/${params}/10/0`, 'get');

		return dispatch({
			type: GET_ALL_RESERVES,
			payload: response,
		});
	};
};

export const getAllReservesCant = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/admin/all/reservas/cant`, 'get');
		return dispatch({
			type: GET_ALL_RESERVES_CANT,
			payload: response,
		});
	};
};
