import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateTarget } from '../../redux/actions/admin/datos';
import { formatNumberWithDots } from '../../utils/formatNumberWithDots';

export const EditRaised = ({ raised, data }) => {
	const dispatch = useDispatch();
	const [toggle, setToggle] = useState(false);
	const [value, setValue] = useState(raised);
	const inputRef = useRef(null);

	const handleChange = e => setValue(e.target.value);

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			handleBlur();
			setToggle(false);
		}
	};

	const handleBlur = () => {
		if (value === raised) return;

		const updateData = {
			...data,
			reached: value,
		};

		dispatch(updateTarget(updateData));
		setToggle(false);
	};

	useEffect(() => {
		const handleClickOutside = e => {
			if (inputRef.current && !inputRef.current.contains(e.target)) {
				setToggle(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<>
			{toggle ? (
				<input
					value={value}
					onChange={handleChange}
					onBlur={handleBlur}
					ref={inputRef}
					type='text'
					style={{ width: '90px' }}
					onKeyPress={handleKeyPress}
				/>
			) : (
				<p onDoubleClick={() => setToggle(true)}>{formatNumberWithDots(raised)}</p>
			)}
		</>
	);
};
