import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { Box } from '@mui/material';

import { useDispatch } from 'react-redux';

import { getLotsClient, getDataClient, getHistoryClient } from '../../redux/actions/client';
import { assignLotToClient, getDevelopmentsAssignLot } from '../../redux/actions/user/lots';
import { authAxios } from '../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export default function TransferList({ handleClose, developmentId, dataClient }) {
	const [checked, setChecked] = useState([]);
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [availableLots, setAvailableLots] = useState([]);

	const dispatch = useDispatch();

	const fetchAvailableLots = async () => {
		const response = await authAxios(`${URL_API}/client/development/lots/${developmentId}`, 'get');

		setAvailableLots(response.LotDetails);
		setLoading(false);
	};

	const handleSubmit = async () => {
		try {
			setSubmitting(true);

			await dispatch(assignLotToClient({ userId: dataClient.id, developmentId, lots: checked }));
			toast.success('Lote/s asignado/s', { theme: 'colored' });
			await dispatch(getLotsClient(dataClient));
			await dispatch(getDataClient(dataClient));
			await dispatch(getDevelopmentsAssignLot());
			await dispatch(getHistoryClient(dataClient.id));

			setSubmitting(false);
			handleClose();
		} catch (error) {
			return toast.error('Hubo un problema, intentalo de nuevo.', { theme: 'colored' });
		}
	};

	useEffect(() => {
		fetchAvailableLots();

		return () => {
			setAvailableLots([]);
			setChecked([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'end', gap: 5 }}>
				{loading ? (
					<div>Cargando...</div>
				) : (
					<List
						sx={{
							width: '100%',
							maxWidth: 360,
							minHeight: 200,
							maxHeight: 300,
							overflowY: 'scroll',
							bgcolor: 'background.paper',
						}}
					>
						{availableLots.map(value => {
							const labelId = `checkbox-list-label-${value.id}`;

							return (
								<ListItem key={value.id} disablePadding>
									<ListItemButton role={undefined} onClick={handleToggle(value.numberLot)} dense>
										<ListItemIcon>
											<Checkbox
												edge='start'
												disableRipple
												tabIndex={-1}
												checked={checked.indexOf(value.numberLot) !== -1}
												inputProps={{ 'aria-labelledby': labelId }}
											/>
										</ListItemIcon>
										<ListItemText
											id={labelId}
											secondary={value.measures}
											primary={`Lote número ${value.numberLot}`}
										/>
									</ListItemButton>
								</ListItem>
							);
						})}
					</List>
				)}

				{!loading && (
					<Box sx={{ display: 'flex', justifyContent: 'end' }}>
						<LoadingButton
							onClick={handleSubmit}
							loading={submitting}
							variant='contained'
							type='submit'
							sx={{ mt: 4, mr: 6 }}
						>
							Asignar
						</LoadingButton>
					</Box>
				)}
			</Box>
		</>
	);
}
