import {
	Box,
	Card,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	CircularProgress,
	Avatar,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Scrollbar } from '../Scrollbar';
import { formatDate } from '../../utils/formatDate';

import { useSelector } from 'react-redux';

import { avatarDesarrollo, avatarElemental, avatarGrupo } from '../../assets/avatars/';

export const ListUsers = () => {
	const isLoading = useSelector(state => state.allUsers.loading);
	const items = useSelector(state => state.allUsers.users);

	return (
		<>
			{isLoading ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<CircularProgress />
				</div>
			) : (
				<Card>
					<Scrollbar>
						<Box sx={{ minWidth: 800 }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										<TableCell align='left'>Nombre - Apellido</TableCell>
										<TableCell align='left'>Rol</TableCell>
										<TableCell align='left'>Area</TableCell>
										<TableCell align='left'>Email</TableCell>
										<TableCell align='left'>Última sesión</TableCell>
										<TableCell align='right'>Fecha de creación</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{items.map(e => {
										return (
											<TableRow hover key={e.id}>
												<TableCell>
													<Avatar>
														<img
															src={
																e.SystemId === 1
																	? avatarElemental
																	: e.SystemId === 2
																	? avatarDesarrollo
																	: avatarGrupo
															}
															alt='Logo desarrollo'
															height={40}
															width={40}
														/>
													</Avatar>
												</TableCell>
												<TableCell align='left' sx={{ cursor: 'pointer' }}>
													<Link
														to={`/usuario/${e.id}`}
														style={{ textDecoration: 'none', color: 'inherit' }}
													>
														<Stack alignItems='center' direction='row' spacing={2}>
															<Typography variant='subtitle2'>{e.fullName}</Typography>
														</Stack>
													</Link>
												</TableCell>
												<TableCell align='left'>
													{e.RoleId === 1
														? 'Administrador'
														: e.RoleId === 2
														? 'Moderador'
														: 'Usuario'}
												</TableCell>
												<TableCell align='left'>
													{e.AreaRolId === 1
														? 'Comercial'
														: e.AreaRolId === 2
														? 'Administración'
														: e.AreaRolId === 3
														? 'Contable'
														: e.AreaRolId === 4
														? 'Community Manager'
														: 'Marketing'}
												</TableCell>
												<TableCell align='left'>{e.email}</TableCell>
												<TableCell align='left'>
													{e.lastLogin ? formatDate(e.lastLogin) : ''}
												</TableCell>
												<TableCell align='right'>{formatDate(e.createdAt)}</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Box>
					</Scrollbar>
				</Card>
			)}
		</>
	);
};
