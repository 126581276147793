import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
	let error = useRouteError();

	return (
		<>
			<Box
				component='main'
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexGrow: 1,
					minHeight: '90vh',
				}}
			>
				<Container maxWidth='md'>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box
							sx={{
								mb: 3,
								textAlign: 'center',
							}}
						></Box>
						<Typography align='center' sx={{ mb: 3 }} variant='h3'>
							Error
						</Typography>
						<Typography align='center' color='text.secondary' variant='body1'>
							{error.message}
						</Typography>
						<Button
							startIcon={
								<SvgIcon fontSize='small'>
									<ArrowLeftIcon />
								</SvgIcon>
							}
							sx={{ mt: 3 }}
							variant='contained'
							onClick={() => window.history.back()}
						>
							Volver atrás
						</Button>
					</Box>
				</Container>
			</Box>
		</>
	);
};
