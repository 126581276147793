import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_ZONES = 'GET_ALL_ZONES';
export const GET_ALL_RESUME_ZONES = 'GET_ALL_RESUME_ZONES';

export const getZones = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/zones`, 'get');
		let result = response.map(e => {
			let parsed = JSON.parse(e.interestedParties);
			return { ...e, interestedParties: parsed };
		});
		return dispatch({
			type: GET_ALL_ZONES,
			payload: result,
		});
	};
};

export const getResumeZones = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/create/contact/zones`, 'get');
		return dispatch({
			type: GET_ALL_RESUME_ZONES,
			payload: response,
		});
	};
};
